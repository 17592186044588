var $mobileHiddenContent;
var $formWrap;

$(document).ready(function () {
    if (!window.waitBeforeYouGo.cookie) {
        setupVideJs();
        setupWaitBeforeYouGo();
    }
    $mobileHiddenContent = $('.mobile-hidden-content');
    $formWrap = $('.form-wrap');

});

//  Toggles a mobile navigation menu layout
$(document).on('click', '#mobile-menu', function() {
    $mobileHiddenContent.toggleClass('show-mobile-content');
    $(this).toggleClass('mobile-menu-open');
    $formWrap.toggleClass('show-mobile-form');
});

//  Setups a Wait Before You Go video
function setupVideJs () {
    $('#video').vide({
        mp4: window.pathToSourceFiles + 'videos/video.mp4',
        flv: window.pathToSourceFiles + 'videos/video.flv'
    }, {
        posterType: 'none',
    });
}

//  Setups a Wait Before You Go
function setupWaitBeforeYouGo() {
    _SetCookie("wait_before_you_go", "true");
    var $waitBeforeYouGo = $('#wait-before-you-go');

    $(document).bind('mouseleave', function () {
        if (window.waitBeforeYouGo.isAlreadyCalled) {
            return;
        }
        $waitBeforeYouGo.removeClass('opacity-off').addClass('opacity-on');

        $waitBeforeYouGo.find('.btn-close').on('click', function () {
            $waitBeforeYouGo.removeClass('opacity-on').addClass('opacity-off');
        });
        window.waitBeforeYouGo.isAlreadyCalled = true;
    });

    function _SetCookie(cookieName,cookieValue) {
        var today = new Date();
        var expire = new Date();
        expire.setTime(today.getTime() + window.waitBeforeYouGo.expires * 1000);
        document.cookie = cookieName + "=" + encodeURI(cookieValue) + ";expires=" + expire.toUTCString();
    }
}